import React from 'react';
import ReactDOM from 'react-dom';
import About from '../js/components/About';
import 'jquery';

// for owl-carousel
window.jQuery = $;
window.$ = $;

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <About />,
    document.body.appendChild(document.createElement('div')),
  )
})
