import React from 'react';

class Header extends React.Component {

  navigateHome = () => {
    window.location = '/'
  }

  render() {
    let titleCharacters = this.props.title.split('');

    return(
      <div className='navbar-fixed'>
        <nav>
          <div className='navbar-wrapper container' onClick={this.navigateHome}>
            <div className='navbar__title'>
              {
                titleCharacters.map((character, index) => {
                  return(
                    <div className='navbar__title--element' key={index}>{character}</div>
                  )
                })
              }

            </div>

            <p className='navbar__subtitle'>
              Limited edition Giclée Prints
            </p>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
