const _authenticityToken = () => {
  return document.querySelector("meta[name='csrf-token']").getAttribute('content')
}

const _loadRequestHeaders = () => {
  let headers = new Headers();
  const csrfToken = _authenticityToken()

  headers.append('X-Requested-With', 'XMLHttpRequest')
  headers.append('X-CSRF-Token', csrfToken)
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json')

  return headers;
}

const fetchRequest = (requestBody, requestUrl, httpMethod = 'PUT') => {
  let fetchArgs = {
    method: httpMethod,
    headers: _loadRequestHeaders()
  }

  if(requestBody && requestBody.length) {
    fetchArgs['body'] = requestBody
  }

  return fetch(requestUrl, fetchArgs)
    .then((response) => {
      if(response.ok) {
        return(
          response.json().then((responseJson) => {
            return { // allow success == false to be passable, in situations where we still want to be able to render response data (e.g. model validation failed on creation, render error messages)
              success: Object.keys(responseJson).includes('success') ? responseJson['success'] : true,
              data: responseJson
            }
          })
        );
      }
      else {
        return { success: false }
      }
    })
}

const _photoMetadataRequest = (httpMethod, token, requestBody) => {
  let fetchArgs = {
    method: httpMethod,
    headers: _loadRequestHeaders()
  }

  if(httpMethod !== 'GET') {
    fetchArgs = Object.assign(fetchArgs, {
      body: JSON.stringify(requestBody)
    });
  }

  return fetch(`/api/photos/${token}`, fetchArgs)
    .then((response) => {
      if(response.ok) {
        return(
          response.json().then((responseJson) => {
            return { metadata: responseJson, success: true };
          })
        );
      }
      else {
        return { success: false };
      }
    });
}

export default {
  loadRequestHeaders: _loadRequestHeaders,
  authenticityToken: _authenticityToken,
  
  loadPhotos: (requestArgs = {}) => {
    let requestUrl = `/api/photos`

    if(requestArgs != {}) {
      ['merge_hidden', 'exclude_series', 'exclude_b_roll', 'load_orderable_hidden'].forEach((param) => {
        if(Object.keys(requestArgs).includes(param)) {
          requestUrl = `${requestUrl}${requestUrl.includes('?') ? '&' : '?'}${param}=${requestArgs[param]}`
        }
      })
    }

    if(Object.keys(requestArgs).includes('dreams_admin')) {
      requestUrl = `${requestUrl}&dreams_admin=true`
    }

    return fetch(requestUrl).then((response) => response.json())
  },

  hidePhoto: (token, mergeHidden, excludeSeries, excludeBRoll) => {
    const requestBody = JSON.stringify({
      token,
      merge_hidden: mergeHidden,
      exclude_series: excludeSeries,
      exclude_b_roll: excludeBRoll
    })

    return fetchRequest(requestBody, `/api/admin/hide_photo`)
  },

  showPhoto: (token, mergeHidden, excludeSeries, excludeBRoll) => {
    const requestBody = JSON.stringify({
      token,
      merge_hidden: mergeHidden,
      exclude_series: excludeSeries,
      exclude_b_roll: excludeBRoll
    })

    return fetchRequest(requestBody, `/api/admin/show_photo`)
  },

  markForReplacement: (token, mergeHidden, excludeSeries, excludeBRoll) => {
    const requestBody = JSON.stringify({
      token,
      merge_hidden: mergeHidden,
      exclude_series: excludeSeries,
      exclude_b_roll: excludeBRoll
    })

    return fetchRequest(requestBody, `/api/admin/mark_for_replacement`)
  },

  unmarkForReplacement: (token, mergeHidden, excludeSeries, excludeBRoll) => {
    const requestBody = JSON.stringify({
      token,
      merge_hidden: mergeHidden,
      exclude_series: excludeSeries,
      exclude_b_roll: excludeBRoll
    })

    return fetchRequest(requestBody, `/api/admin/unmark_for_replacement`)
  },

  markForDownload: (token, mergeHidden, excludeSeries, excludeBRoll) => {
    const requestBody = JSON.stringify({
      token,
      merge_hidden: mergeHidden,
      exclude_series: excludeSeries,
      exclude_b_roll: excludeBRoll
    })

    return fetchRequest(requestBody, `/api/admin/mark_for_download`)
  },

  unmarkForDownload: (token, mergeHidden, excludeSeries, excludeBRoll) => {
    const requestBody = JSON.stringify({
      token,
      merge_hidden: mergeHidden,
      exclude_series: excludeSeries,
      exclude_b_roll: excludeBRoll
    })

    return fetchRequest(requestBody, `/api/admin/unmark_for_download`)
  },

  markForDeletion: (token, mergeHidden, excludeSeries, excludeBRoll) => {
    const requestBody = JSON.stringify({
      token,
      merge_hidden: mergeHidden,
      exclude_series: excludeSeries,
      exclude_b_roll: excludeBRoll
    })

    return fetchRequest(requestBody, `/api/admin/mark_for_deletion`)
  },

  unmarkForDeletion: (token, mergeHidden, excludeSeries, excludeBRoll) => {
    const requestBody = JSON.stringify({
      token,
      merge_hidden: mergeHidden,
      exclude_series: excludeSeries,
      exclude_b_roll: excludeBRoll
    })

    return fetchRequest(requestBody, `/api/admin/unmark_for_deletion`)
  },

  addPrintToOrder: (order_token, photo_token) => {
    const requestBody = JSON.stringify({ photo_token })

    return fetchRequest(requestBody, `/api/orders/${order_token}/add_photo`, 'PATCH')
  },

  removePrintFromOrder: (order_token, photo_token) => {
    const requestBody = JSON.stringify({
      order_token,
      photo_token
    })

    return fetchRequest(requestBody, '/api/orders/remove_photo', 'DELETE')
  },

  updateOrder: (order_token, params) => {
    const requestBody = JSON.stringify(params)

    return fetchRequest(requestBody, `/api/orders/${order_token}`, 'PATCH')
  },

  confirmOrder: (order_token) => {
    return fetchRequest({}, `/api/orders/${order_token}/confirm`)
  },

  markOrderAsAWaitingPrint: (order_token) => {
    return fetchRequest({}, `/api/orders/${order_token}/awaiting_print`)
  },

  payOrder: (order_token) => {
    return fetchRequest({}, `/api/orders/${order_token}/pay`)
  },

  shipOrder: (order_token) => {
    return fetchRequest({}, `/api/orders/${order_token}/ship`)
  },

  completeOrder: (order_token) => {
    return fetchRequest({}, `/api/orders/${order_token}/complete`)
  },

  cancelOrder: (order_token, params = {}) => {
    const requestBody = JSON.stringify(params)

    return fetchRequest(requestBody, `/api/orders/${order_token}/cancel`)
  },

  receiveAffiliatePayout: (order_token) => {
    return fetchRequest({}, `/api/orders/${order_token}/receive_affiliate_payout`)
  },

  createAffiliateCode: (code) => {
    const requestBody = JSON.stringify({ affiliate_code: { code } })

    return fetchRequest(requestBody, '/affiliate_codes', 'POST')
  },

  updateAffiliateCode: (affiliate_code_id, code) => {
    const requestBody = JSON.stringify({ affiliate_code: { code } })

    return fetchRequest(requestBody, `/affiliate_codes/${affiliate_code_id}`)
  },

  applyDiscountCode: (order_token, code) => {
    const requestBody = JSON.stringify({ discount_code: { code } })

    return fetchRequest(requestBody, `/api/orders/${order_token}/apply_discount_code`, 'PATCH')
  },

  swapAspectGroupIndexes: (sourceToken, targetToken, mergeHidden, excludeSeries, excludeBRoll) => {
    const requestBody = JSON.stringify({
      source_token: sourceToken,
      target_token: targetToken,
      merge_hidden: mergeHidden,
      exclude_series: excludeSeries,
      exclude_b_roll: excludeBRoll
    });

    return fetchRequest(requestBody, '/api/admin/swap_aspect_group_indexes');
  },

  updatePhotoMetadata: (token, params) => {
    return _photoMetadataRequest('PUT', token, params);
  },

  getCryptoQuote: (orderToken, cryptoTokenName) => {
    return fetchRequest({}, `/api/orders/${orderToken}/quote_price_in_crypto_token?crypto_token_name=${cryptoTokenName}`, 'GET')
  },

  validateAffiliateCode: (code) => {
    return fetchRequest({}, `/api/affiliate_codes/validate/${code}`, 'GET')
  },

  createPrintJob: (orderTokens) => {
    const requestBody = JSON.stringify({ order_tokens: orderTokens })

    return fetchRequest(requestBody, '/api/print_jobs', 'POST')
  },

  addToPrintJob: (orderToken, PrintJobToken) => {
    const requestBody = JSON.stringify({ print_job_token: PrintJobToken })

    return fetchRequest(requestBody, `/api/orders/${orderToken}/add_to_print_job`)
  },

  createPrintJobQuote: (params) => {
    const requestBody = JSON.stringify(params)

    return fetchRequest(requestBody, '/api/print_job_quotes', 'POST')
  },
  
  updatePrintJobQuote: (printJobQuoteToken, params) => {
    const requestBody = JSON.stringify(params)
    
    return fetchRequest(requestBody, `/api/print_job_quotes/${printJobQuoteToken}`, 'PATCH')
  },
  
  createSeries: (params) => {
    const requestBody = JSON.stringify(params)

    return fetchRequest(requestBody, '/dreams_admin/series', 'POST')
  },
  
  updateSeries: (seriesToken, params) => {
    const requestBody = JSON.stringify(params)

    return fetchRequest(requestBody, `/dreams_admin/series/${seriesToken}`, 'PATCH')
  },

  destroySeries: (seriesToken) => {
    const requestBody = JSON.stringify({})
  
    return fetchRequest(requestBody, `/dreams_admin/series/${seriesToken}`, 'DELETE')
  },

  createBankAccountDetails: (params) => {
    const requestBody = JSON.stringify(params)

    return fetchRequest(requestBody, ' /api/bank_account_details', 'POST')
  },

  updateBankAccountDetails: (token, params) => {
    const requestBody = JSON.stringify(params)

    return fetchRequest(requestBody, `/api/bank_account_details/${token}`, 'PATCH')
  },

  destroyBankAccountDetails: (token) => {
    const requestBody = JSON.stringify({})
  
    return fetchRequest(requestBody, `/api/bank_account_details/${token}`, 'DELETE')
  },

  printJobStateTransition: (printJobToken, stateTransition) => {
    const requestBody = JSON.stringify({})

    return fetchRequest(requestBody, `/api/print_jobs/${printJobToken}/${stateTransition}`, 'PUT')
  },

  markPrintJobPaid: (printJobToken, paymentNote) => {
    const requestBody = JSON.stringify({ payment_note: paymentNote })

    return fetchRequest(requestBody, `/api/print_jobs/${printJobToken}/pay`, 'PUT')
  },

  // returns boolean in response body - used for checking order print editions are available before trying to process payment
  printEditionsAreAvailable: (orderToken) => {
    return fetchRequest({}, `/api/orders/${orderToken}/print_editions_are_available`, 'GET')
  }
}
