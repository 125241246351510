import React from 'react'
// import CryptoHelper from '../../helpers/CryptoHelper'

class CostsReview extends React.Component {
  action = () => {
    if(this.props.actionDisabled) {
      return
    }

    this.props.action()
  }

  render() {

    let totalCostString = this.props.totalCost
    const shippingCost = this.props.shippingCost

    const discountCodeMetadata = this.props.discountCodeMetadata

    if([undefined, null].includes(shippingCost)) {
      totalCostString = `${totalCostString} + delivery`
    }

    return(
      <div className='costs-review'>
        <div className='costs-review__contents-wrapper'>
          { this.props.deliveryMethod &&
            <React.Fragment>
              <p>
                <span>{`${this.props.pluraliseCollectionName('Print')}:`}</span>
                <span>{this.props.printsCost}</span>
              </p>
              { discountCodeMetadata && // precalculated by backend
                <p>
                  <span>{`${this.props.pluraliseCollectionName('Print')} ${discountCodeMetadata['percentage']}% discount:`}</span>
                  <span>{`-${discountCodeMetadata['amount']}`}</span>
                </p>
              }
              <p>
                <span>Delivery:</span>
                <span>{shippingCost || 'TBD'}</span>
              </p>
              <p>
                <span>TOTAL:</span>
                {/* <span>{`${totalCost} ${this.props.cryptoPriceData && this.props.cryptoPriceData['crypto_token_name'] && this.props.cryptoPriceData['order_cost_in_crypto'] ? ` / ${this.props.cryptoPriceData['order_cost_in_crypto']} ${CryptoHelper.metadata(this.props.cryptoPriceData['crypto_token_name']).symbol}` : ''}${this.props.isPaid ? '(PAID)' : ''}`}</span> */}
                <span>{totalCostString}</span>
              </p>
            </React.Fragment>
          }
          { !this.props.deliveryMethod &&
            <React.Fragment>
              <p>
                <span>Delivery:</span>
                <span>{shippingCost || 'TBD'}</span>
              </p>
              <p>
                <span>TOTAL:</span>
                <span>{`€${totalCostString}`}</span>
              </p>
            </React.Fragment>
          }

          { this.props.action && this.props.actionText &&
            <div className='button button--action button--full-width' disabled={this.props.actionDisabled ? 'disabled' : null} onClick={this.action}>
              <div className='contents-wrapper'>
                <span>{ this.props.actionText }</span>
                <i className={`fa ${this.props.faClassList || 'fa-solid fa-arrow-right'}`}></i>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default CostsReview
